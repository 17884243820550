import React from "react"
import loadable from "@loadable/component"
import { graphql } from "gatsby"

const Layout = loadable(() => import("@common/Layout/Layout"))
const SEO = loadable(() => import("@components/Common/SEO/SEO"))
const Telecommunication = loadable(() => import("@components/Telecommunication"))

const TelecommunicationPage = ({ path, data }) => {
  return (
    <>
      <SEO
        title="Telecommunication software development services | Boost Your Connectivity"
        titleTemplate="Impressit"
        SEOData={data.allStrapiSeoDescriptions?.nodes}
        description="Enhance your telecommunication services with our custom software development solutions. Get tailored software solutions for better connectivity today!"
        isNotIndexed={false}
        ownMeta={{
            name: "googlebot",
            content: "all"
        }}
        path={path}
      />
      <Layout path={path} isNoPadding>
        <Telecommunication />
      </Layout>
    </>
  )
}
export default TelecommunicationPage

export const telecommunicationPageQuery = graphql`
    query telecommunicationPageSEODataQuery {
        allStrapiSeoDescriptions {
            nodes {
                Title
                MetaTitle
                PageUrl
            }
        }
    }
`;
